/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import Hero from '../../components/Hero'
import RichText from '../../components/RichText'
import ContentWrapper from '../../components/ContentWrapper'
import LineLimitContainer from '../../components/LineLimitContainer'
import ExampleCard from '../../components/ExampleCard'
import ExampleGrid from '../../components/ExampleGrid'
import SectionHeading from '../../components/SectionHeading'
import ShareBanner from '../../components/ShareBanner'
import { A, P, H2 } from '../../components/Typography'
import { BREAKPOINT, SPACING } from '../../constants'

const NoExamples = styled.section`
  margin-top: 7rem;
  margin-bottom: 7rem;
  padding-left: 0;

  @media (min-width: ${BREAKPOINT.S}) {
    margin-top: 10rem;
    margin-bottom: 10rem;
    padding-left: ${SPACING.M};
  }
`

const patternTemplate = ({ data: { pattern, patternIcon, shareBanner } }) => (
  <Layout title={pattern.name} seoDescription={pattern.seoDescription}>
    <Hero
      type="pattern"
      heading={pattern.name}
      tags={pattern.relatedPrinciples}
      heroImage={patternIcon.icon && patternIcon.icon}
      description={pattern.description}
    />
    <ContentWrapper>
      {pattern.furtherDescription && (
        <section>
          <SectionHeading>Further description</SectionHeading>
          <LineLimitContainer>
            {RichText(pattern.furtherDescription)}
          </LineLimitContainer>
        </section>
      )}
      {pattern.example ? (
        <section>
          <SectionHeading>Examples</SectionHeading>

          <ExampleGrid>
            {pattern.example.map((example) => (
              <ExampleCard
                key={example.slug}
                slug={example.slug}
                name={example.name}
                thumbnail={example.thumbnail}
                organisation={example.contributingOrganisation.name}
              />
            ))}
          </ExampleGrid>
        </section>
      ) : (
        <NoExamples>
          <H2>No examples available</H2>
          <P>
            If you would like to contribute an example of this pattern in
            action, please{' '}
            <A href="mailto:lucy.stewart@wearesnook.com">get in touch.</A>
          </P>
        </NoExamples>
      )}
    </ContentWrapper>
    {shareBanner && (
      <ShareBanner
        heading={shareBanner.heading}
        image={shareBanner.icon}
        position={shareBanner.iconPosition}
        bodyText={shareBanner.bodyText}
      />
    )}
  </Layout>
)

export const query = graphql`
  query($slug: String) {
    pattern: contentfulPattern(slug: { eq: $slug }) {
      name
      seoDescription
      example {
        name
        slug
        contributingOrganisation {
          name
        }
        thumbnail {
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid
          }
          description
        }
      }
      description {
        json
      }
      furtherDescription {
        json
      }
      relatedPrinciples {
        name
        slug
      }
    }
    patternIcon: contentfulContentTypeInfo(contentType: { eq: "pattern" }) {
      contentType
      icon {
        file {
          url
        }
      }
    }
    shareBanner: contentfulShareBanner(type: { eq: "pattern" }) {
      heading
      bodyText
      iconPosition
      icon {
        title
        file {
          url
        }
      }
    }
  }
`

export default patternTemplate
